<template>
  <div class="table-responsive adaptive-table sentMessageTable" v-if="list.length > 0">
    <table class="table table-striped table-hover " style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :class="item === 'text' ? 'col-md-4' : ''" :key="key">{{ $t(item) }}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id">
          <td :data-label="$t('id')" @click="prepareInfo(row)">
            {{ $t('webhookTitle') }}: {{ row.webhook_id }}<br />
            <span v-if="row.message_id">{{ $t('messageTitle') }}: {{ row.message_id }}</span>
            <span v-if="row.request_id">{{ $t('requestTitle') }}: {{ row.request_id }}</span>
          </td>
          <td :data-label="$t('status')">
            {{ row.status }}
          </td>
          <td :data-label="$t('attempt')">
            {{ row.attempt }} / {{ row.max_attempts }}
          </td>
          <td :data-label="$t('eventType')">{{ row.event_type }}</td>
          <td :data-label="$t('lastUpdate')">{{ humanDateTime(row.update_date, $i18n.locale) }}</td>
          <td :data-label="$t('date')">{{ humanDateTime(row.create_date, $i18n.locale) }}</td>
          <td>
            <a
              v-if="sentRetryEvents.findIndex(item => item === row.webhook_id) === -1"
              @click.prevent="() => sendRetryHandler(row.webhook_id)"
              href="#"
            >{{ $t('retry') }}</a>
            <spinner-component v-if="sentRetryEvents.findIndex(item => item === row.webhook_id) > -1" item-class="table-spinner-layout"></spinner-component>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, defineAsyncComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHumanDate } from '@/application/composables/humanDate.js'
import { useTableHandlers } from '@/application/composables/messagesTable.js'

export default {
  name: 'WebhookHistoryTable',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  emits: ['retry'],
  setup(props, { emit }) {
    const { humanDateTime } = useHumanDate()
    const i18n = useI18n()
    const { openModal } = useTableHandlers()
    const fields = [
      'id', 'status', 'attempt', 'eventType',
      'lastUpdate', 'dateCreated',
    ]
    const sentRetryEvents = ref([])
    const sendRetryHandler = (id) => {
      sentRetryEvents.value.push(id)
      setTimeout(() => {
        const index = sentRetryEvents.value.findIndex(item => item === id)
        sentRetryEvents.value.splice(index, 1)
      }, 5000)
      emit('retry', id)
    }

    const prepareInfo = (info) => {
      const obj = {
        date: humanDateTime(info.create_date, i18n.locale),
        callbackUrl: info.callback_url || '-',
        responseCode: info.response_code,
        destinationData: info.json,
      }
      openModal(obj, 'webhookHistoryDetails')
    }
    return {
      humanDateTime,
      prepareInfo,
      fields,
      sentRetryEvents,
      sendRetryHandler,
    }
  },
}
</script>
